import type { KeyboardEvent, ChangeEvent } from 'react';
import { Fragment, useContext, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import type { FormikContextType } from 'formik';
import { useFormikContext } from 'formik';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import { useHistory } from 'react-router-dom';
import ConfirmField from '../../../../Common/ConfirmField/ConfirmField';
import CashField from '../../../../Common/CashField/CashField';
import CustomerBillers from './CustomerBillers/CustomerBillers';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import Processing from '../../../../Common/Processing/Processing';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { useRDM } from 'components/hooks/RDM';
import Civitek from '../AdditionalInfoPopups/Civitek';
import { focusNextElement } from '../../../../../utilities/Tools';
import './BillerForm.css';
import type { CustomerBillerDetailProps, initialValues } from '../BillPay';
import { tutorialAccount, tutorialAmount, tutorialBiller, tutorialBillerZip } from '../BillPay';
import type { BillerDetail } from 'components/hooks/BillPay';
import { getTooltipClass, getTooltipTriggerClass, tooltipBoldClass } from 'utilities/tooltip';
import { getClass } from 'utilities/classnames';

interface Props {
    onSearchEvent: (event: ChangeEvent<HTMLInputElement>) => void,
    totalCheckAmt: number,
    isBillerSearchTutorial: boolean,
    isPreviousBillersTutorial: boolean,
    isBillerZipTutorial: boolean,
    isAccountTutorial: boolean,
    isAccountConfirmTutorial: boolean,
    isAmountTutorial: boolean,
    isSubmitTutorial: boolean,
    getBillerDetail: (props: CustomerBillerDetailProps, formik?: FormikContextType<typeof initialValues>) => Promise<BillerDetail | undefined>
}

const unallowedChars = "{}[]:\";'<>?,./|\\~`!@#=$%^&*()_+-".split('');

export default function BillerForm({ onSearchEvent, totalCheckAmt, isBillerSearchTutorial, isPreviousBillersTutorial,
    isBillerZipTutorial, isAccountTutorial, isAccountConfirmTutorial, isAmountTutorial, isSubmitTutorial, getBillerDetail }: Props) {

    const [scanning, setScanning] = useState(false);
    const [popupOpen, setPopupOpen] = useState(true);

    const { push } = useHistory();
    const { values, setFieldValue, validateForm, handleReset, setStatus, errors } = useFormikContext<typeof initialValues>();
    const { parms } = useContext(ParmContext);
    const { billStubScan } = useRDM();

    const prevBillerIdRef = useRef<number | undefined>();

    const total = useMemo(() => {
        const cash = values.cash;
        const fee = values.biller?.custFee;
        if (cash && fee) {
            return (totalCheckAmt + parseInt(cash) + parseFloat(fee) * 100) / 100;
        }
        return 0;
    }, [totalCheckAmt, values.biller?.custFee, values.cash])

    const formattedCheckAmt = '$' + (totalCheckAmt / 100).toFixed(2);

    const showCheckForm = useCallback(() => {
        push('/BillPay/CheckForm');
    }, [push])

    const handleCheckKeydown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (isFinite(Number(event.key)) || event.keyCode === 13) {
            showCheckForm();
        }
    }, [showCheckForm])

    const isOnCustomerField = document.activeElement?.getAttribute('name')?.includes('customer');

    const shouldFocusCashField = !!values.biller && values.biller.billerId >= 0
        && !!values.accountNum
        && !isOnCustomerField;

    const moneyInputs = useMemo(() => {
        if (parms?.parameters.processCheck && values.biller?.checks === 'Y') {
            return (
                <div className='CashCheck'>
                    <div>
                        <label>Cash</label>
                        <CashField
                            autoFocus={shouldFocusCashField}
                            name='cash'
                            trainingFocused={isAmountTutorial}
                            trainingTooltip={
                                <>
                                    <div>ENTER THE AMOUNT</div>
                                    <b className={tooltipBoldClass}>{'$' + (Number(tutorialAmount) / 100).toFixed(2)}</b>
                                </>
                            }
                            placement='top' />
                    </div>
                    <div>
                        <label>Check</label>
                        <input onClick={showCheckForm}
                            onKeyDown={handleCheckKeydown}
                            value={formattedCheckAmt}
                            readOnly={true} />
                    </div>
                </div>
            );
        } else {
            return <CashField
                autoFocus={shouldFocusCashField}
                name='cash'
                trainingFocused={isAmountTutorial}
                trainingTooltip={
                    <>
                        <div>ENTER THE AMOUNT</div>
                        <b className={tooltipBoldClass}>{'$' + (Number(tutorialAmount) / 100).toFixed(2)}</b>
                    </>
                }
                placement='top' />
        }
    }, [formattedCheckAmt, handleCheckKeydown, isAmountTutorial, parms?.parameters.processCheck, shouldFocusCashField, showCheckForm, values.biller?.checks])

    const scanBillStub = useCallback(async () => {
        if (values.biller) {
            try {
                setScanning(true);
                setStatus({ message: '' });
                const response = await billStubScan(values.imageName, values.biller, total * 100);
                setFieldValue('accountNum', '');
                setFieldValue('accountNumConfirm', '');
                setFieldValue('billStubImage', response);
            } catch (error) {
                setStatus({ message: error, messageType: 'error' });
            } finally {
                setScanning(false);
            }
        }
    }, [billStubScan, setFieldValue, setStatus, total, values.biller, values.imageName])

    const canScanBill = parms?.parameters.scanBill
        && !!values.biller
        && (values.biller.ocrH > 0
            || values.biller.ocrW > 0
            || values.biller.ocrX > 0
            || values.biller.ocrY > 0)

    const clearOCR = useCallback(() => {
        setFieldValue('billStubImage', null);
    }, [setFieldValue])


    const accountLabel = values.biller?.deocrLbl || "Account Number";

    const validateAccount = useCallback((value: string) => {
        const specialChars = (values.biller?.specialChars || values.biller?.requiredChars || '');
        const requiredChars = (values.biller?.requiredChars ?? '').split('');
        if (!value) {
            return 'Account required';
        } else if (unallowedChars.some((char) => value.includes(char) && !specialChars.includes(char))) {
            return `Invalid ${accountLabel}`;
        } else if (requiredChars.some((char) => !value.includes(char))) {
            return `Invalid ${accountLabel}`;
        }
    }, [accountLabel, values.biller?.requiredChars, values.biller?.specialChars])

    const shouldFocusConfirmFields = (values.biller?.billerId ?? -1) >= 0
        && !values.accountNum
        && !isOnCustomerField;

    const handlePopupOpen = () => {
        setPopupOpen(true);
    }

    const handlePopupClose = () => {
        setPopupOpen(false);
    }

    const billerPopup = useMemo(() => {
        const hasBillerPopup = [3289, 3290].includes(values.biller?.billerId ?? 0);
        return <Civitek onClose={handlePopupClose} open={hasBillerPopup && popupOpen} />
    }, [popupOpen, values.biller?.billerId])

    const billerHasCustomFields = useCallback(() => {
        return [3289, 3290].includes(values.biller?.billerId ?? 0);
    }, [values.biller?.billerId])

    const accountOcrInputs = useMemo(() => {
        if (billerHasCustomFields()) {
            return (
                <div>
                    <AdvancedButton type='button'
                        className='ExtraFieldsButton'
                        onClick={handlePopupOpen}>Extra Biller Fields</AdvancedButton>
                </div>
            )
        } else {
            if (values.billStubImage) {
                return (
                    <div className='OCRBlock'>
                        <label>OCR</label>
                        <button onClick={clearOCR}>Clear OCR / Go Back</button>
                        <AdvancedField name='billStubImage.Ocr.OcrZone0.OcrLine'
                            className='Ocr'
                            tooltip={
                                <>
                                    <div>Please Verify OCR</div>
                                    <div>was read properly.</div>
                                </>
                            }
                            maxLength={80} />
                    </div>
                );
            } else {
                return (
                    <div className={getClass([
                        'AccountConfirm',
                        getTooltipTriggerClass(!!values.biller?.notes || undefined)
                    ])}>
                        <AdvancedField
                            name='accountNum'
                            placeholder={accountLabel}
                            maxLength={60}
                            validate={validateAccount}
                            required upperCase
                            autoFocus={shouldFocusConfirmFields}
                            trainingFocused={isAccountTutorial}
                            trainingTooltip={
                                <>
                                    <div>ENTER THE ACCOUNT NUMBER</div>
                                    <b className={tooltipBoldClass}>{tutorialAccount}</b>
                                </>
                            } tooltipPlacement='left' />
                        <ConfirmField
                            name='accountNum'
                            placeholder={accountLabel}
                            maxLength={60}
                            required upperCase
                            trainingFocused={isAccountConfirmTutorial}
                            trainingTooltip={
                                <>
                                    <div>ENTER THE ACCOUNT NUMBER AGAIN</div>
                                    <b className={tooltipBoldClass}>{tutorialAccount}</b>
                                </>
                            }
                            tooltipPlacement='left' />
                        {!!values.biller?.notes &&
                            <div className={getTooltipClass({ highlight: true })}>
                                <span className="NotesTooltip">{values.biller?.notes}</span>
                            </div>
                        }
                    </div>
                )
            }
        }
    }, [accountLabel, billerHasCustomFields, clearOCR, isAccountConfirmTutorial, isAccountTutorial, shouldFocusConfirmFields, validateAccount, values.billStubImage, values.biller?.notes])

    const isValidCustomer = !Object.keys(errors).includes('customer');

    const needZip = values.biller && values.biller.billerId < 0;

    const shouldFocusBillerField = (values.billerSearch.length > 0)
        && !values.biller
        && !(values.customerSearch.length > 0);

    const handleResetButton = useCallback(() => {
        handleReset();
        focusNextElement();
    }, [handleReset])

    useEffect(() => {
        if (prevBillerIdRef.current !== values.biller?.billerId) {
            setPopupOpen(true);
        }
        prevBillerIdRef.current = values.biller?.billerId;
    }, [values.biller?.billerId]);

    useEffect(() => {
        if (values.biller && values.biller.billerName !== values.billerSearch) {
            setFieldValue('billerSearch', values.biller.billerName);
        }
        validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() =>
        <fieldset className='BillerForm'>
            <AdvancedField name='billerSearch'
                type='search'
                onChange={onSearchEvent}
                placeholder='SEARCH OVER 30,000 BILLERS'
                autoFocus={shouldFocusBillerField} //When you are backing-out of the biller search.
                upperCase={true}
                maxLength={120}
                trainingFocused={isBillerSearchTutorial}
                trainingTooltip={
                    <>
                        <div>NOW THAT YOU HAVE SELECTED THE CUSTOMER, SEARCH FOR THE BILLER</div>
                        <b className={tooltipBoldClass}>{tutorialBiller.billerName}</b>
                    </>
                }
                tooltipPlacement='top' />
            {isValidCustomer && values.biller && !values.biller.suspendedMessage &&
                <Fragment>
                    {!needZip &&
                        <div className='PaymentInformation'>
                            <div>Fee: ${values.biller.custFee}</div>
                            <div>Delivery time: {values.biller.processDays}</div>
                        </div>
                    }
                    {needZip &&
                        <AdvancedField name='biller.billerZip'
                            placeholder='BILLER ZIP CODE'
                            tooltip={<>A ZIP CODE IS REQUIRED TO VERIFY CORRECT BILLER SELECTION</>}
                            upperCase={true}
                            autoFocus={true}
                            maxLength={12}
                            trainingFocused={isBillerZipTutorial}
                            trainingTooltip={
                                <>
                                    <div>ENTER THE BILLER ZIP</div>
                                    <b className={tooltipBoldClass}>{tutorialBillerZip}</b>
                                </>
                            } />
                    }
                    {billerPopup}
                    {accountOcrInputs}
                    {moneyInputs}
                    {(parms?.addressRequired || parms?.parameters.addressRequired) &&
                        <div className='LegalMessage'>No Bill Payments Over $2,999.99</div>
                    }
                    <div className='PaymentTotalBlock'>
                        {values.biller.billerId < 0 &&
                            <span className='ConsolidatedFee'>Fee: ${values.biller.custFee}</span>
                        }
                        {total > 0 &&
                            <span className='PaymentTotal'>Total to collect: ${total.toFixed(2)}</span>
                        }
                    </div>
                </Fragment>
            }
            {values.biller?.suspendedMessage &&
                <div className='SuspendedMessage' >
                    {values.biller.suspendedMessage}
                </div>
            }
            {values.customer.billers.length > 0 && !values.biller &&
                <CustomerBillers getBillerDetail={getBillerDetail}
                    isPreviousBillersTutorial={isPreviousBillersTutorial} />
            }
            <div className='Buttons'>
                <AdvancedButton type='reset'
                    className='Reset'
                    tabIndex={-1}
                    onClick={handleResetButton}>Reset</AdvancedButton>
                {canScanBill &&
                    <AdvancedButton type='button'
                        className='Scan'
                        onClick={scanBillStub}
                        disabled={!!values.biller?.suspendedMessage}>Scan Bill</AdvancedButton>
                }
                <div>
                    <AdvancedButton
                        className='Pay'
                        trainingFocused={isSubmitTutorial}
                        trainingTooltip={
                            <>
                                <div>YOU ARE READY</div>
                                <b className={tooltipBoldClass}>CLICK PAY NOW</b>
                            </>
                        }
                        disabled={!!values.biller?.suspendedMessage}
                        tooltipPlacement='left' >Pay Now</AdvancedButton>
                </div>
            </div>
            <Processing open={scanning}>
                <h3>Place bill stub in scanner.</h3>
            </Processing>
        </fieldset >
        , [accountOcrInputs, billerPopup, canScanBill, getBillerDetail, handleResetButton, isBillerSearchTutorial, isBillerZipTutorial, isPreviousBillersTutorial, isSubmitTutorial, isValidCustomer, moneyInputs, needZip, onSearchEvent, parms?.addressRequired, parms?.parameters.addressRequired, scanBillStub, scanning, shouldFocusBillerField, total, values.biller, values.customer.billers.length]);
}