import AdvancedForm from 'components/Common/AdvancedForm/AdvancedForm';
import { AnalyticsContext } from 'components/HigherOrder/AnalyticsController/AnalyticsController';
import { useBillPay } from 'components/hooks/BillPay';
import type { BillerDetail, PaymentHistory, PaymentResponse } from 'components/hooks/BillPay';
import type { FormikHelpers } from 'formik';
import { Fragment, useCallback, useContext, useState } from 'react';
import { useLocation, useHistory, Switch, Route } from "react-router-dom";
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import AmountForm from './AmountForm/AmountForm';
import CardForm from './CardForm.tsx/CardForm';
import CartForm from './CartForm.tsx/CartForm';
import PhoneForm from './PhoneForm/PhoneForm';
import TypeForm from './TypeForm/TypeForm';
import { useBillPayPrinter } from 'components/Common/Receipts/BillPayPrinter';
import { focusNextElement } from 'utilities/Tools';
import PaymentComplete from './PaymentComplete/PaymentComplete';

export const initialValues = {
    card: "",
    cardConfirm: "",
    amount: "0",
    phone: "",
    phoneConfirm: ""
}

export default function NetSpend() {

    const [billerDetail, setBillerDetail] = useState<BillerDetail | null>(null);

    const { pathname } = useLocation();
    const { push, goBack } = useHistory();
    const [isReloadTraining, setReloadTraining] = useState(false);
    const [paymentCompleteClosed, setPaymentCompleteClosed] = useState(true);

    const onPaymentCompleteClose = useCallback(() => {
        setPaymentCompleteClosed(true);
        focusNextElement();
    }, [])

    const { setTrainingSetting } = useContext(SettingsContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { billPayRequest, parsePaymentErrors, getImageName } = useBillPay();
    const { printLastPayment } = useBillPayPrinter(false);
    const { endTransaction } = useContext(AnalyticsContext);

    const [lastPayment, setLastPayment] = useState(null as null | PaymentHistory);

    const validate = useCallback((values: typeof initialValues) => {
        if (pathname.includes("/PhoneForm")) {
            if (values.phone.length < 7) {
                return { phone: 'Please enter a valid phone number.' };
            }
        } else if (pathname.includes('/CardForm')) {
            if (billerDetail?.billerId === 3327 && values.card.length !== 11) {
                return { card: 'Please enter a valid account number.' };
            } else if (billerDetail?.billerId !== 3327 && values.card.length !== 16) {
                return { card: 'Please enter a valid card number.' };
            }
        } else if (pathname.includes('/AmountForm')) {
            const total = Number(values.amount);
            if (total < 2000) {
                return { amount: 'Payments must be $20.00 or more.' };
            } else if (total > 50000) {
                return { amount: 'Payments must be less than $500.00.' };
            }
        }
        return {};
    }, [billerDetail?.billerId, pathname]);

    const printReceipt = useCallback(async (values: typeof initialValues, paymentResponse: PaymentResponse["billpay"]) => {
        if (billerDetail && paymentResponse.bp_payment) {
            const lastPayment = {
                billerId: billerDetail.billerId,
                date: paymentResponse.bp_payment.rcptdt,
                time: paymentResponse.bp_payment.rcpttm,
                trace: paymentResponse.bp_payment.detrac,
                authCode: paymentResponse.bp_payment.deauth,
                cashAmt: Number(values.amount),
                checkAmt: 0,
                totalAmt: Number(values.amount),
                totalFee: billerDetail.custFee,
                accountNum: paymentResponse.bp_payment.decust,
                receiptText: paymentResponse.bp_payment.receipt_text,
                postingTime: billerDetail.processDays,
                originalBillerName: billerDetail.billerName,
                finalBillerName: paymentResponse.billerName ? paymentResponse.billerName : billerDetail.billerName,
                transTime: endTransaction(),
                customerName: '',
                customerPhone: values.phone,
                customerZip: '',
                customerEmail: ''
            };
            await printLastPayment(lastPayment);
            setLastPayment(lastPayment);
            setPaymentCompleteClosed(false);
        }
    }, [billerDetail, endTransaction, printLastPayment])

    const makePayment = useCallback(async (values: typeof initialValues, { setStatus, resetForm, setSubmitting }: FormikHelpers<typeof initialValues>) => {
        if (billerDetail) {
            try {
                const paymentResponse = await billPayRequest({
                    biller: billerDetail,
                    accountNum: values.card,
                    imageName: getImageName(),
                    cash: values.amount,
                    customer: {
                        active: 'A',
                        address1: '',
                        address2: '',
                        city: '',
                        email: '',
                        id: '0',
                        name: '',
                        phone: values.phone,
                        state: '',
                        zip: ''
                    }
                });

                const errors = parsePaymentErrors(paymentResponse?.bp_payment);
                if (errors) {
                    push('/NetSpend');
                    resetForm();
                    setBillerDetail(null);
                    setStatus({ message: errors, messageType: 'error' });
                } else if (paymentResponse?.bp_payment) {
                    await printReceipt(values, paymentResponse);
                    push('/NetSpend');
                    resetForm();
                    setBillerDetail(null);
                    setStatus({ message: paymentResponse.bp_payment.response_message + " " + (paymentResponse.bp_payment.response_message2 ?? ''), messageType: '' });
                }
            } catch (error) {
                console.error(error);
                goBack();
                setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
            } finally {
                setSubmitting(false);
            }
        }
    }, [billPayRequest, billerDetail, getImageName, goBack, parsePaymentErrors, printReceipt, push])

    const handleSubmit = useCallback(async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (pathname.includes('/PhoneForm')) {
            push('/NetSpend/CardForm');
            actions.setSubmitting(false);
        } else if (pathname.includes('/CardForm')) {
            push('/NetSpend/AmountForm');
            actions.setSubmitting(false);
        } else if (pathname.includes('/AmountForm')) {
            push('/NetSpend/CartForm');
            actions.setSubmitting(false);
        } else if (pathname.includes('/CartForm')) {
            if (!isCurrentPageTraining) {
                await makePayment(values, actions);
            } else {
                if (isReloadTraining) {
                    setTrainingSetting({ netspend: true });
                } else {
                    push('/NetSpend');
                    actions.resetForm();
                    setBillerDetail(null);
                    setReloadTraining(true);
                }
            }
        }
    }, [isCurrentPageTraining, isReloadTraining, makePayment, pathname, push, setTrainingSetting])

    return (
        <AdvancedForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            numericOnly={true} >
            <Fragment>
                <Switch>
                    {!!billerDetail &&
                        [
                            <Route path='/NetSpend/PhoneForm' key={1} render={() =>
                                <PhoneForm />} />,
                            <Route path='/NetSpend/CardForm' key={2} render={() =>
                                <CardForm
                                    billerDetail={billerDetail} />} />,
                            <Route path='/NetSpend/AmountForm' key={3} render={() =>
                                <AmountForm
                                    billerDetail={billerDetail} />} />,
                            <Route path='/NetSpend/CartForm' key={4} render={() =>
                                <CartForm
                                    billerDetail={billerDetail} />} />
                        ]
                    }
                    <Route render={() =>
                        <TypeForm
                            setBillerDetail={setBillerDetail}
                            isReloadTraining={isReloadTraining} />}
                    />
                </Switch>
                {!!lastPayment &&
                    <PaymentComplete lastPayment={lastPayment} onClose={onPaymentCompleteClose} open={!paymentCompleteClosed} />
                }
            </Fragment>
        </AdvancedForm>
    );
}