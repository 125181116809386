import { useContext, useEffect, useState, useCallback } from 'react';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import { useHistory, useLocation, Route, Switch } from "react-router-dom";
import { AnalyticsContext } from '../../../HigherOrder/AnalyticsController/AnalyticsController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { useBillPay } from 'components/hooks/BillPay';
import type { PaymentResponse, BillerDetail, Customer } from 'components/hooks/BillPay';
import type { FormikHelpers } from 'formik';
import { getBestCustomer, getFirstLast } from '../DigitalGiftCards/DigitalGiftCards';
import NamePhoneForm from '../DigitalGiftCards/NamePhoneForm/NamePhoneForm';
import { useBillPayPrinter } from '../../../Common/Receipts/BillPayPrinter';
import CartForm from './CartForm/CartForm';
import Amount from './Amount/Amount';

export const tutorialAmount = '3000';

export const initialValues = {
    phone: "",
    phoneConfirm: "",
    firstName: "",
    lastName: "",
    cash: '0'
};

export default function CashLoad() {

    const { push, goBack } = useHistory();
    const { pathname } = useLocation();
    const { endTransaction } = useContext(AnalyticsContext);
    const { setTrainingSetting } = useContext(SettingsContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { getBillerDetailRequest, billPayRequest, parsePaymentErrors, customerSearchRequest, getImageName } = useBillPay();
    const { printLastPayment } = useBillPayPrinter(true);

    const [searched, setSearched] = useState(false);
    const [customer, setCustomer] = useState<null | Customer>(null);
    const [amazonBiller, setAmazonBiller] = useState<null | BillerDetail>(null);

    const getCustomers = useCallback(async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        actions.setFieldValue('firstName', '');
        actions.setFieldValue('lastName', '');
        setCustomer(null);
        try {
            const customerResponse = await customerSearchRequest(values.phone);
            if (customerResponse && customerResponse.customerInfo.length > 0) {
                const customer = getBestCustomer(customerResponse.customerInfo);
                const { firstName, lastName } = getFirstLast(customer);
                actions.setFieldValue('firstName', firstName);
                actions.setFieldValue('lastName', lastName);
                setCustomer(customer);
            }
            push('/CashLoad/Name');
        } catch (error) {
            console.error(error);
        } finally {
            actions.setSubmitting(false);
        }
        setSearched(true);
    }, [customerSearchRequest, push]);

    const printReceipt = useCallback(async (values: typeof initialValues, paymentResponse: PaymentResponse["billpay"]) => {
        if (amazonBiller && paymentResponse.bp_payment) {
            await printLastPayment({
                billerId: amazonBiller.billerId,
                date: paymentResponse.bp_payment.rcptdt,
                time: paymentResponse.bp_payment.rcpttm,
                trace: paymentResponse.bp_payment.detrac,
                authCode: paymentResponse.bp_payment.deauth,
                cashAmt: Number(values.cash),
                checkAmt: 0,
                totalAmt: Number(values.cash),
                totalFee: amazonBiller.custFee,
                accountNum: paymentResponse.bp_payment.decust,
                receiptText: paymentResponse.bp_payment.receipt_text,
                postingTime: amazonBiller.processDays,
                originalBillerName: amazonBiller.billerName,
                finalBillerName: paymentResponse.billerName ? paymentResponse.billerName : amazonBiller.billerName,
                transTime: endTransaction(),
                customerName: values.firstName.trim() + ' ' + values.lastName,
                customerPhone: values.phone,
                customerZip: '',
                customerEmail: ''
            });
        }
    }, [amazonBiller, endTransaction, printLastPayment]);

    const validate = useCallback((values: typeof initialValues) => {
        if (pathname.includes('/Amount')) {
            const total = Number(values.cash);
            if (total < 2500) {
                return { cash: 'Payments must be $25.00 or more.' };
            } else if (total > 50000) {
                return { cash: 'Payments must be less than $500.00.' };
            }
        } else {
            if (values.phone.length < 7) {
                return { phone: 'Please enter a valid phone number.' };
            }
        }
        return {};
    }, [pathname]);

    const makePayment = useCallback(async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (amazonBiller) {
            try {
                const paymentResponse = await billPayRequest({
                    customer: {
                        active: 'A',
                        id: customer?.id ?? '0',
                        name: values.firstName.trim() + ' ' + values.lastName,
                        phone: values.phone,
                        address1: customer?.address1 ?? '',
                        address2: customer?.address2 ?? '',
                        city: customer?.city ?? '',
                        state: customer?.state ?? '',
                        zip: customer?.zip ?? '',
                        email: customer?.email ?? ''
                    },
                    biller: amazonBiller,
                    accountNum: values.phone,
                    imageName: getImageName(),
                    cash: values.cash
                });
                const errors = parsePaymentErrors(paymentResponse?.bp_payment);
                if (errors) {
                    goBack();
                    actions.setStatus({ message: errors, messageType: 'error' });
                } else if (paymentResponse?.bp_payment) {
                    await printReceipt(values, paymentResponse);
                    push('/CashLoad');
                    actions.resetForm();
                    setCustomer(null);
                    setSearched(false);
                    actions.setStatus({ message: paymentResponse.bp_payment.response_message + " " + (paymentResponse.bp_payment.response_message2 ?? ''), messageType: '' });
                }
            } catch (error) {
                console.error(error);
                goBack();
                actions.setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
            } finally {
                actions.setSubmitting(false);
            }
        }
    }, [amazonBiller, billPayRequest, customer, getImageName, goBack, parsePaymentErrors, printReceipt, push])

    const handleSubmit = useCallback(async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (pathname.includes('/Amount')) {
            push('/CashLoad/Checkout');
            actions.setSubmitting(false);
        } else if (pathname.includes('/Checkout')) {
            if (!isCurrentPageTraining) {
                await makePayment(values, actions);
            } else {
                setTrainingSetting({ amazonCash: true });
            }
        } else {
            if (pathname.includes('/Name')) {
                push('/CashLoad/Amount');
            } else {
                await getCustomers(values, actions);
            }
        }
    }, [getCustomers, isCurrentPageTraining, makePayment, pathname, push, setTrainingSetting])

    const clearCustomers = useCallback(() => {
        goBack();
        setCustomer(null);
        setSearched(false);
    }, [goBack]);

    const getBillerDetail = useCallback(async () => {
        const response = await getBillerDetailRequest({ billerId: 3306 });
        if (response) {
            setAmazonBiller(response);
        }
    }, [getBillerDetailRequest]);

    useEffect(() => {
        getBillerDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdvancedForm className='CashLoad'
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate} >
            <Switch>
                <Route path='/CashLoad/Amount' render={() =>
                    <Amount />} />
                <Route path='/CashLoad/Checkout' render={() =>
                    <CartForm />} />
                <Route render={() =>
                    <NamePhoneForm
                        title='WELCOME TO AMAZON CASH'
                        searched={searched}
                        found={!!customer}
                        clearCustomers={clearCustomers} />} />
            </Switch>
        </AdvancedForm>
    );
}