import { Fragment, useContext, useState, useCallback, useMemo } from 'react';
import type { ChangeEvent } from 'react';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import { useHistory, useLocation, Route, Switch } from "react-router-dom";
import BillerForm from './BillerForm/BillerForm';
import BillerSearch from './BillerSearch/BillerSearch';
import CustomerForm from './CustomerForm/CustomerForm';
import CustomerSearch from './CustomerSearch/CustomerSearch';
import Processing from '../../../Common/Processing/Processing';
import MessageAggregator from '../../../Common/MessageAggregator/MessageAggregator';
import CheckForm from './CheckForm/CheckForm';
import SearchForm from './SearchForm/SearchForm';
import TrainingSelectionPopup from './TrainingSelectionPopup/TrainingSelectionPopup';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { AnalyticsContext } from '../../../HigherOrder/AnalyticsController/AnalyticsController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import type { BillScanResponseData, CheckScanResponseData } from 'components/hooks/RDM';
import './BillPay.css';
import type { FormikContextType, FormikHelpers, FormikProps } from 'formik';
import { useBillPay, getTotal, getTotalCheckAmt } from 'components/hooks/BillPay';
import type { BillerDetail, BillerDetailProps, PaymentHistory, PaymentResponse } from 'components/hooks/BillPay';
import { useBillPayPrinter } from 'components/Common/Receipts/BillPayPrinter';
import { focusNextElement } from 'utilities/Tools';
import PaymentComplete from './PaymentComplete/PaymentComplete';

export interface CustomerBillerDetailProps extends BillerDetailProps {
    acct?: string,
    ocr?: string
}

export const tutorialBillerZip = '01234';
export const tutorialAmount = '1500';
export const tutorialAccount = 'TRAIN123';

export const tutorialCustomer = {
    active: "A",
    address1: "1301 MAIN ST",
    address2: "",
    city: "SULPHUR SPRINGS",
    email: "",
    id: "-999",
    name: "GEORGE SCOTTY",
    phone: "8006218030",
    state: "TX",
    zip: "75482",
    billers: [{
        "billerId": -999,
        "billerName": "FIDELITY ELECTRIC",
        "billerAddr": "*Multi-Biller Option",
        "billerCity": "",
        "billerState": "",
        "billerZip": "01234",
        "payType": "CASH",
        "billerAccounts": [{
            ocr: "",
            acct: tutorialAccount
        }]
    }]
};

export const tutorialBiller = {
    "agentId": 400101,
    "billerId": -999,
    "extbillerId": 0,
    "billerName": "FIDELITY ELECTRIC",
    "billerAddr": "*Multi-Biller Option",
    "billerCity": "",
    "billerState": "",
    "billerZip": "01234",
    "processType": "CASH",
    "processDays": "Next business day",
    "checks": "N", "custFee": "2.00",
    "agentFee": "1.00",
    "processFee": "1.00",
    "ocrX": 0,
    "ocrY": 0,
    "ocrW": 0,
    "ocrH": 0,
    "ocrType": "A",
    "printPost": "N",
    "achProcessor": "",
    "comment": "",
    "rtFlag": "",
    "billHoldCode": "",
    "category": "",
    "payeeId": "0",
    "notes": "",
    "processTimeFlag": "",
    "specialChars": "-",
    "requiredChars": "",
    "suspendedMessage": "",
    "payType": "CASH",
    "deocrLbl": ""
};

const initialRequestValues = {
    requestBillerName: '',
    requestBillerAddress: '',
    requestBillerCity: '',
    requestBillerState: '',
    requestBillerZip: ''
}

const initialCustomerValues = {
    active: "A",
    address1: "",
    address2: "",
    city: "",
    email: "",
    id: "0",
    name: "",
    phone: "",
    state: "",
    zip: "",
    billers: [] as typeof tutorialCustomer.billers
};

const initialCheckValues = {
    checkInput: '0',
    checks: [] as CheckScanResponseData[]
}

const initialAdditionalFields = {
    dlNumber: "",
    dlState: "",
    birthdate: {
        day: '',
        month: '',
        year: ''
    },
    street: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    email: "",
    firstName: "",
    lastName: ""
}

export const initialValues = {
    customer: initialCustomerValues,
    originalCustomer: null as null | typeof initialCustomerValues,
    customerSearch: '',
    biller: null as null | BillerDetail,
    billerSearch: '',
    accountNum: '',
    accountNumConfirm: '',
    billStubImage: null as null | BillScanResponseData,
    imageName: '',
    cash: '0',
    ...initialCheckValues,
    ...initialRequestValues,
    ...initialAdditionalFields
};

const triggerCustomerValidation = (formik: FormikContextType<typeof initialValues>) => {
    formik.setTouched({
        ...formik.touched,
        customer: {
            address1: true,
            city: true,
            email: true,
            name: true,
            phone: true,
            state: true,
            zip: true,
        }
    });
}

const getAdditionalFields = ({ billerId }: BillerDetail, { values: { customer: { name, address1, city, email, state, zip } } }: FormikContextType<typeof initialValues>) => {
    if ([3289, 3290].includes(billerId)) {
        const commonFields = {
            street: address1,
            city: city,
            state: state,
            zip: zip,
            email: email,
            firstName: name.split(' ')[0],
            lastName: name.split(' ').slice(1).join(' ')
        }

        if ([3289].includes(billerId)) {
            return {
                ...commonFields,
                dlNumber: '',
                dlState: '',
                birthdate: {
                    day: '',
                    month: '',
                    year: ''
                },
            }
        } else if ([3290].includes(billerId)) {
            return {
                ...commonFields,
                county: ''
            }
        }
    } else {
        return {};
    }
}

/** 
 * Gets the last payment, which should have been saved to localStorage. Returns null if nothing is found.
*/
const getSavedLastPayment = () => {
    const lastPayment = localStorage.getItem('lastPayment');
    return lastPayment ? JSON.parse(lastPayment) as PaymentHistory : null;
}

export default function BillPay() {

    const { push, replace } = useHistory();
    const { pathname } = useLocation();
    const { parms } = useContext(ParmContext);
    const { endTransaction } = useContext(AnalyticsContext);
    const { setTrainingSetting } = useContext(SettingsContext);
    const { isCurrentPageTraining, isGlobalTraining } = useContext(TrainingContext);
    const { getBillerDetailRequest, billPayRequest, parsePaymentErrors, getImageName } = useBillPay();
    const { printLastPayment } = useBillPayPrinter(true);

    const [lastPayment, setLastPayment] = useState(getSavedLastPayment());
    const [newCustomerTraining, setNewCustomerTraining] = useState(false);
    const [previousCustomerTraining, setPreviousCustomerTraining] = useState(false);
    const [newCustomerTrainingComplete, setNewCustomerTrainingComplete] = useState(false);
    const [previousCustomerTrainingComplete, setPreviousCustomerTrainingComplete] = useState(false);
    const [paymentCompleteClosed, setPaymentCompleteClosed] = useState(true);

    const onPaymentCompleteClose = useCallback(() => {
        setPaymentCompleteClosed(true);
        focusNextElement();
    }, [])

    const startnewCustomerTraining = () => {
        setNewCustomerTraining(true);
    }

    const startPreviousCustomerTraining = () => {
        setPreviousCustomerTraining(true);
    }

    const stopAllTraining = () => {
        setNewCustomerTraining(false);
        setPreviousCustomerTraining(false);
        setNewCustomerTrainingComplete(true);
        setPreviousCustomerTrainingComplete(true);
    }

    const saveLastPayment = (lastPayment: PaymentHistory) => {
        localStorage.setItem("lastPayment", JSON.stringify(lastPayment));
        setLastPayment(lastPayment);
    }

    const isDuplicate = useCallback((values: typeof initialValues) => {
        const checkAmt = getTotalCheckAmt(values.checks);
        const total = parseInt(values.cash) + checkAmt;
        return lastPayment
            && lastPayment.accountNum === values.accountNum
            && lastPayment.totalAmt === total
            && lastPayment.originalBillerName === values.biller?.billerName
    }, [lastPayment])

    const validate = useCallback((values: typeof initialValues, { setStatus }: FormikHelpers<typeof initialValues>) => {
        const checkAmt = getTotalCheckAmt(values.checks);
        const total = parseInt(values.cash) + checkAmt;
        if (!values.biller) {
            setStatus({ message: 'Please Select A Biller.', messageType: 'error' });
        } else if (total < 100) {
            setStatus({ message: 'Payments must be $1.00 or more.', messageType: 'error' });
        } else if (isDuplicate(values)) {
            setStatus({ message: `The exact payment, biller, and ${values.biller.deocrLbl || 'account number'} was previously processed on this terminal. Please change the payment amount by at least 1 cent to continue.`, messageType: 'error' });
        } else if ((parms?.addressRequired || parms?.parameters.addressRequired) && total > 299999) {
            setStatus({ message: '$$ exceeds limit.', messageType: 'error' });
        } else {
            return true;
        }
    }, [isDuplicate, parms?.addressRequired, parms?.parameters.addressRequired])

    const handleTrainingSubmit = useCallback(({ resetForm }: FormikHelpers<typeof initialValues>) => {
        if (isGlobalTraining) {
            const newNewCustomerTrainingComplete = newCustomerTraining || newCustomerTrainingComplete;
            const newPreviousCustomerTrainingComplete = previousCustomerTraining || previousCustomerTrainingComplete;

            if (newNewCustomerTrainingComplete && newPreviousCustomerTrainingComplete) {
                setTrainingSetting({ bp: true });
            } else {
                resetForm();
                setNewCustomerTraining(false);
                setPreviousCustomerTraining(false);
                setNewCustomerTrainingComplete(newNewCustomerTrainingComplete);
                setPreviousCustomerTrainingComplete(newPreviousCustomerTrainingComplete);
            }
        } else {
            setTrainingSetting({ bp: true });
            stopAllTraining();
        }
    }, [isGlobalTraining, newCustomerTraining, newCustomerTrainingComplete, previousCustomerTraining, previousCustomerTrainingComplete, setTrainingSetting])

    const getBillerDetail = useCallback(async (values: CustomerBillerDetailProps, formik?: FormikContextType<typeof initialValues>) => {
        let promise: Promise<BillerDetail | undefined>;
        if (values.billerId === tutorialBiller.billerId) {
            promise = Promise.resolve(tutorialBiller);
        } else {
            promise = getBillerDetailRequest(values);
        }

        const response = await promise;
        if (formik && response) {
            push('/BillPay');
            formik.setValues({
                ...formik.values,
                biller: response,
                billerSearch: response.billerName,
                accountNum: (values.acct || values.ocr) ?? '',
                accountNumConfirm: (values.acct || values.ocr) ?? '',
                billStubImage: null,
                imageName: getImageName(),
                cash: '0',
                ...initialCheckValues,
                ...getAdditionalFields(response, formik)
            });

            triggerCustomerValidation(formik);
            if (['AUTOINS', 'UNKNOWN'].includes(response.category)) {
                formik.setStatus({ message: 'This biller requires an address', messageType: 'error' });
            }
        }
        return response;
    }, [getBillerDetailRequest, getImageName, push])

    const getBillerIfChanged = useCallback(async (values: typeof initialValues, paymentResponse: PaymentResponse["billpay"]) => {
        if (Number(paymentResponse.bp_payment!.debilr) !== values.biller!.billerId) {
            const billerDetail = await getBillerDetail({ billerId: Number(paymentResponse.bp_payment!.debilr) });
            if (billerDetail) {
                return {
                    ...values.biller,
                    custFee: billerDetail.custFee,
                    processDays: billerDetail.processDays
                }
            }
        }
        return values.biller;
    }, [getBillerDetail]);

    const onSubmit = useCallback(async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        //Customer Information Handled Field-Level
        //Account Numbers Handled Field-Level

        if (isCurrentPageTraining) {
            return handleTrainingSubmit(actions);
        }

        if (!validate(values, actions)) {
            return actions.setSubmitting(false);
        }

        if (values.biller) {
            try {
                const paymentResponse = await billPayRequest(values);
                const errors = parsePaymentErrors(paymentResponse?.bp_payment);
                if (errors) {
                    actions.setStatus({ message: errors, messageType: 'error' });
                } else if (paymentResponse?.bp_payment) {
                    const biller = await getBillerIfChanged(values, paymentResponse);

                    const lastPayment = {
                        billerId: biller!.billerId ?? Number(paymentResponse.bp_payment.debilr),
                        date: paymentResponse.bp_payment.rcptdt,
                        time: paymentResponse.bp_payment.rcpttm,
                        trace: paymentResponse.bp_payment.detrac,
                        authCode: paymentResponse.bp_payment.deauth,
                        cashAmt: parseInt(values.cash),
                        checkAmt: getTotalCheckAmt(values.checks),
                        totalAmt: getTotal(values),
                        totalFee: biller!.custFee,
                        accountNum: paymentResponse.bp_payment.decust,
                        receiptText: paymentResponse.bp_payment.receipt_text,
                        postingTime: biller!.processDays,
                        originalBillerName: values.biller.billerName,
                        finalBillerName: paymentResponse.billerName ? paymentResponse.billerName : values.biller.billerName,
                        transTime: endTransaction(),
                        customerName: values.customer.name,
                        customerPhone: values.customer.phone,
                        customerZip: values.customer.zip,
                        customerEmail: values.customer.email
                    };
                    await printLastPayment(lastPayment);
                    saveLastPayment(lastPayment);
                    setPaymentCompleteClosed(false);
                    actions.setFieldValue('biller', null);
                    actions.setFieldValue('billerSearch', '');
                    actions.setStatus({ message: paymentResponse.bp_payment.response_message + " " + (paymentResponse.bp_payment.response_message2 ?? ''), messageType: '' });
                }
            } catch (error) {
                console.error(error);
                actions.setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
            } finally {
                actions.setSubmitting(false);
            }
        }
    }, [billPayRequest, endTransaction, getBillerIfChanged, handleTrainingSubmit, isCurrentPageTraining, parsePaymentErrors, printLastPayment, validate])

    return useMemo(() =>
        <AdvancedForm className='BillPay' initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, isSubmitting, setFieldValue }: FormikProps<typeof initialValues>) => {

                const isCustomerSearchTutorial = values.customer.id !== tutorialCustomer.id && values.customerSearch.length < 3
                    && previousCustomerTraining;

                const isCustomerSelectionTutorial = values.customer.id !== tutorialCustomer.id && values.customerSearch.length >= 3
                    && previousCustomerTraining;

                const isCustomerNameTutorial = values.customer.name !== tutorialCustomer.name
                    && newCustomerTraining;

                const isCustomerPhoneTutorial = !isCustomerNameTutorial
                    && values.customer.phone !== tutorialCustomer.phone
                    && newCustomerTraining;

                const isCustomerAddressTutorial = !isCustomerNameTutorial
                    && !isCustomerPhoneTutorial
                    && values.customer.address1 !== tutorialCustomer.address1
                    && newCustomerTraining;

                const isCustomerCityTutorial = !isCustomerNameTutorial
                    && !isCustomerPhoneTutorial
                    && !isCustomerAddressTutorial
                    && values.customer.city !== tutorialCustomer.city
                    && newCustomerTraining;

                const isCustomerStateTutorial = !isCustomerNameTutorial
                    && !isCustomerPhoneTutorial
                    && !isCustomerAddressTutorial
                    && !isCustomerCityTutorial
                    && values.customer.state !== tutorialCustomer.state
                    && newCustomerTraining;

                const isCustomerZipTutorial = !isCustomerNameTutorial
                    && !isCustomerPhoneTutorial
                    && !isCustomerAddressTutorial
                    && !isCustomerCityTutorial
                    && !isCustomerStateTutorial
                    && values.customer.zip !== tutorialCustomer.zip
                    && newCustomerTraining;

                const customerTutorialComplete = (!isCustomerSearchTutorial
                    && !isCustomerSelectionTutorial
                    && previousCustomerTraining)
                    || (!isCustomerNameTutorial
                        && !isCustomerPhoneTutorial
                        && !isCustomerAddressTutorial
                        && !isCustomerCityTutorial
                        && !isCustomerStateTutorial
                        && !isCustomerZipTutorial
                        && newCustomerTraining);

                const isBillerSearchTutorial = customerTutorialComplete
                    && values.biller?.billerId !== tutorialBiller.billerId
                    && values.billerSearch.length < 3
                    && newCustomerTraining;

                const isPreviousBillersTutorial = customerTutorialComplete
                    && values.biller?.billerId !== tutorialBiller.billerId
                    && values.billerSearch.length < 3
                    && previousCustomerTraining;

                const isBillerSelectionTutorial = customerTutorialComplete
                    && values.biller?.billerId !== tutorialBiller.billerId
                    && values.billerSearch.length >= 3;

                const isBillerZipTutorial = customerTutorialComplete
                    && !isBillerSearchTutorial
                    && !isPreviousBillersTutorial
                    && !isBillerSelectionTutorial
                    && values.biller?.billerZip !== tutorialBillerZip;

                const isAccountTutorial = customerTutorialComplete
                    && !isBillerSearchTutorial
                    && !isPreviousBillersTutorial
                    && !isBillerSelectionTutorial
                    && !isBillerZipTutorial
                    && values.accountNum !== tutorialAccount;

                const isAccountConfirmTutorial = customerTutorialComplete
                    && !isBillerSearchTutorial
                    && !isPreviousBillersTutorial
                    && !isBillerSelectionTutorial
                    && !isBillerZipTutorial
                    && !isAccountTutorial
                    && values.accountNumConfirm !== tutorialAccount;

                const isAmountTutorial = customerTutorialComplete
                    && !isBillerSearchTutorial
                    && !isPreviousBillersTutorial
                    && !isBillerSelectionTutorial
                    && !isBillerZipTutorial
                    && !isAccountTutorial
                    && !isAccountConfirmTutorial
                    && values.cash !== tutorialAmount;

                const isSubmitTutorial = customerTutorialComplete
                    && !isBillerSearchTutorial
                    && !isPreviousBillersTutorial
                    && !isBillerSelectionTutorial
                    && !isBillerZipTutorial
                    && !isAccountTutorial
                    && !isAccountConfirmTutorial
                    && !isAmountTutorial;

                const customerSearchRequestChange = (newSearch: string) => {
                    if (newSearch.length >= 3) {
                        const encodedSearch = encodeURIComponent(newSearch);
                        if (pathname === '/BillPay') {
                            push(`/BillPay/CustomerSearch?search=${encodedSearch}`);
                        } else {
                            replace(`/BillPay/CustomerSearch?search=${encodedSearch}`);
                        }

                        if (Number(values.customer.id) > 0) {
                            setFieldValue('customer', initialCustomerValues);
                            setFieldValue('originalCustomer', null);
                        }
                    } else if (pathname === '/BillPay/CustomerSearch') {
                        push('/BillPay');
                    }

                    if (!values.biller && values.billerSearch) {
                        setFieldValue('billerSearch', '');
                    }
                }

                const customerSearchRequestEvent = (event: ChangeEvent<HTMLInputElement>) => {
                    customerSearchRequestChange(event.target.value);
                }

                const billerSearchRequestChange = (newSearch: string) => {
                    if (values.biller && newSearch !== values.biller.billerName) {
                        setFieldValue('biller', null);
                        setFieldValue('accountNum', '');
                        setFieldValue('accountNumConfirm', '');
                        setFieldValue('billStubImage', null);
                    }

                    if (values.customerSearch) {
                        setFieldValue('customerSearch', '');
                    }

                    const matchesName = values.biller?.billerName === newSearch;
                    if (newSearch.length >= 3 && !matchesName) {
                        const encodedSearch = encodeURIComponent(newSearch);
                        if (pathname === '/BillPay') {
                            push(`/BillPay/BillerSearch?search=${encodedSearch}`);
                        } else {
                            replace(`/BillPay/BillerSearch?search=${encodedSearch}`);
                        }
                    } else if ((newSearch.length < 3 || matchesName) && pathname === '/BillPay/BillerSearch') {
                        push('/BillPay');
                    }
                }

                const billerSearchRequestEvent = (event: ChangeEvent<HTMLInputElement>) => {
                    billerSearchRequestChange(event.target.value);
                }

                return (
                    <Fragment>
                        <MessageAggregator />
                        <Switch>
                            <Route path='/BillPay/CheckForm' component={CheckForm} />
                            <Route path='/BillPay/SearchForm' render={() => <SearchForm
                                getBillerDetail={getBillerDetail} />} />
                            <Route path='/BillPay/CustomerSearch' render={() => <CustomerSearch
                                onSearchChange={customerSearchRequestChange}
                                onSearchEvent={customerSearchRequestEvent}
                                isCustomerSelectionTutorial={isCustomerSelectionTutorial} />} />
                            <Route path='/BillPay/BillerSearch' render={() => <BillerSearch
                                onSearchChange={billerSearchRequestChange}
                                onSearchEvent={billerSearchRequestEvent}
                                isBillerSelectionTutorial={isBillerSelectionTutorial}
                                getBillerDetail={getBillerDetail} />} />
                            <Route>
                                <Fragment>
                                    <CustomerForm
                                        onSearchEvent={customerSearchRequestEvent}
                                        isCustomerSearchTutorial={isCustomerSearchTutorial}
                                        isCustomerNameTutorial={isCustomerNameTutorial}
                                        isCustomerPhoneTutorial={isCustomerPhoneTutorial}
                                        isCustomerAddressTutorial={isCustomerAddressTutorial}
                                        isCustomerCityTutorial={isCustomerCityTutorial}
                                        isCustomerStateTutorial={isCustomerStateTutorial}
                                        isCustomerZipTutorial={isCustomerZipTutorial} />
                                    <BillerForm
                                        onSearchEvent={billerSearchRequestEvent}
                                        totalCheckAmt={getTotalCheckAmt(values.checks)}
                                        isBillerSearchTutorial={isBillerSearchTutorial}
                                        isPreviousBillersTutorial={isPreviousBillersTutorial}
                                        isBillerZipTutorial={isBillerZipTutorial}
                                        isAccountTutorial={isAccountTutorial}
                                        isAccountConfirmTutorial={isAccountConfirmTutorial}
                                        isAmountTutorial={isAmountTutorial}
                                        isSubmitTutorial={isSubmitTutorial}
                                        getBillerDetail={getBillerDetail} />
                                </Fragment>
                            </Route>
                        </Switch>
                        <Processing open={isSubmitting} />
                        {!!lastPayment &&
                            <PaymentComplete lastPayment={lastPayment} onClose={onPaymentCompleteClose} open={!paymentCompleteClosed} />
                        }
                        <TrainingSelectionPopup onClose={stopAllTraining}
                            startnewCustomerTraining={startnewCustomerTraining}
                            startPreviousCustomerTraining={startPreviousCustomerTraining}
                            newCustomerTrainingComplete={newCustomerTrainingComplete}
                            previousCustomerTrainingComplete={previousCustomerTrainingComplete}
                            open={!newCustomerTraining && !previousCustomerTraining} />
                    </Fragment>
                );
            }
            }
        </AdvancedForm>
        , [getBillerDetail, lastPayment, newCustomerTraining, newCustomerTrainingComplete, onPaymentCompleteClose, onSubmit, pathname, paymentCompleteClosed, previousCustomerTraining, previousCustomerTrainingComplete, push, replace]);
}